.App {
  text-align: center;
}

.App-container {
  /*background-color: #cae4ce;*/
  min-height: 100vh;
  min-width: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 20px;
}

.App-link {
  color: #61dafb;
  margin: 26px 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Nunito', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeStyle {
  padding: '10px';
  background-color: #878686;
  margin: "10px";
}

.PText {
  font-family: Montserrat;
  color: black;
  line-height: 1.6;
  max-width: 920px;
  padding: 0px 22px;
  text-align: left;
  margin-left: 10px;
}

.WelcomeText {
  font-family: 'Homemade Apple', cursive;
  font-size: 2.5em;
  color: black;
  margin-bottom: 0px;
}

