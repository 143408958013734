body {
  font-family: Montserrat;
}

.HeaderText {
  color: black;
  margin-top: 75px;
  text-align: center;
}

.PageTitle {
  color: black;
  font-family: Montserrat;
}

.SectionText {
  color: black;
  line-height: 1.6;
  max-width: 920px;
  padding: 0px 22px;
  text-align: left;
}

.SectionText span {
  display: block;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 10px;
}

.flex-container > div {
  width: 215px;
  text-align: center;
  line-height: 35px;
}

@media (max-width: 509px) {
    .flex-container {
      display: flex;
      justify-content: center;
  }
}
