.ContactForm {
  background-color: rgb(57,61,66);
  border-radius: 6px;
  margin: auto;
  padding: 12px;
}

.ContactLabel {
  display: inline-block;
  float: left;
}
