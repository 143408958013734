.CardContainer {
  background-color: #8a968a;
  border-radius: 6px;
  font-family: 'Montserrat', sans-serif;
  height: auto;
  margin: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.CardImage {
  border: 4px;
  border-radius: 8%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  width: 90%;
}

.CardTitle {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px 8px;
}

.CardDescription {
  font-size: 12px;
  line-height: normal;
  margin: 0px 10px;
  overflow-wrap: normal;
  padding: 8px;
  text-align: center;
}

.CardRetail {
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 0px;
}
